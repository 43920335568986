import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import AddSuspensionPoint from 'src/components/add-suspension-point/add-suspension-point';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const PlayerProfileAddSuspensionPointsPage = () => {
  return (
    <Router basepath="/players/profile">
      <PlayerProfileAddSuspensionPoints path="/:id" />
    </Router>
  );
};

interface PlayerProfileAddSuspensionPointsRouteProps extends RouteComponentProps {
  id?: string;
}

const PlayerProfileAddSuspensionPoints: React.FC<PlayerProfileAddSuspensionPointsRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Add suspension points" />
      <PrivateRoute>
        <AddSuspensionPoint />
      </PrivateRoute>
    </Layout>
  );
};

export default PlayerProfileAddSuspensionPointsPage;
